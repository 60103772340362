import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Params, Router, UrlCreationOptions, UrlTree } from '@angular/router';

import { NavController } from '@ionic/angular';

import { filter, map } from 'rxjs/operators';

import { BaseNavigationService } from '@semmie/services/navigation/__abstract/base-navigation.service';
import { MainRouteNames } from '@onyxx/model/main';
import { NavigationOptions } from '@ionic/angular/common/providers/nav-controller';
import { ConfigService } from '@semmie/services';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class NavigationService extends BaseNavigationService {
  backNavigationBeta$$ = toSignal(this.configService.config$.pipe(map((config) => config.config.features?.backNavigationBeta?.enabled)));

  constructor(
    private configService: ConfigService,
    private router: Router,
    private navCtrl: NavController,
    private location: Location,
  ) {
    super();
    this.router.events.pipe(filter((r): r is NavigationEnd => r instanceof NavigationEnd)).subscribe((r) => this.addRouteUrl(r.url));
  }

  navigate(commands: Array<any>, extendedOpts?: NavigationOptions, params?: { backwards: boolean }): Promise<any> {
    const opts: Partial<NavigationExtras> = { ...(extendedOpts ?? {}) };

    const nextCommand = this.getCommand(commands);

    if (opts?.replaceUrl) {
      this.allRoutes = [];
    }

    if (params?.backwards) {
      return this.navCtrl.navigateBack(nextCommand, opts);
    }

    return this.navCtrl.navigateForward(nextCommand, opts);
  }

  navigateByUrl(url: string | UrlTree | any[], extraParams?: NavigationOptions) {
    return this.navCtrl.navigateRoot(url, extraParams);
  }

  async pop(options: { defaultBackPath: string[] | UrlTree | unknown[]; relativeTo?: ActivatedRoute; queryParams?: Params }) {
    const result = await this.navCtrl.pop();
    if (result) return;

    await this.navCtrl.navigateBack(options.defaultBackPath, { relativeTo: options.relativeTo, queryParams: options.queryParams });
  }

  /**
   * @deprecated Use pop instead
   */
  async back() {
    if (this.previousRoute) {
      this.navCtrl.back();
      this.allRoutes.pop();
    } else {
      const prevPath = this.location.path().split('/').slice(0, -1);
      await this.navigate(prevPath, { replaceUrl: true }, { backwards: true });
    }
  }

  /**
   * @deprecated. Navigate to the root yourself
   */
  toDashboard(backwards?: boolean, clearHistory?: boolean): void {
    if (clearHistory) {
      this.navCtrl.navigateRoot(['/', MainRouteNames.Accounts], { replaceUrl: true });
      return;
    }

    if (backwards) {
      this.navCtrl.navigateBack(['/', MainRouteNames.Accounts], { replaceUrl: true });
    } else {
      this.navCtrl.navigateForward(['/', MainRouteNames.Accounts], { replaceUrl: true });
    }
  }

  /**
   * @deprecated Use router.createUrlTree instead
   **/
  createUrlTree(commands: any[], navigationExtras?: UrlCreationOptions): UrlTree {
    return this.router.createUrlTree(commands, navigationExtras);
  }
}
