import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { concatLatestFrom } from '@ngrx/operators';
import { MainRouteNames } from '@onyxx/model/main';
import { PushNotification } from '@onyxx/model/notifications';
import { AuthFacade } from '@onyxx/store/auth';
import { NotificationsFacade } from '@onyxx/store/notifications';
import { PushNotificationConfig, PushNotificationsFacade } from '@onyxx/store/push-notifications';
import { Utils } from '@onyxx/utility/general';
import { ConfigService } from '@semmie/services/config/config.service';
import { NavigationService } from '@semmie/services/navigation/navigation.service';
import { ConfigStore } from '@semmie/store';
import { UserStoreFacade } from '@semmie/store/user';
import { SettingsRouteNames } from '@semmie/views/settings/settings.common';
import { filter, firstValueFrom, map, of, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PushNotificationsConfigService implements PushNotificationConfig {
  private readonly authFacade = inject(AuthFacade);
  private readonly configStore = inject(ConfigStore);
  private readonly configService = inject(ConfigService);
  private readonly notificationsFacade = inject(NotificationsFacade);
  private readonly navigationService = inject(NavigationService);
  private readonly pushNotificationsFacade = inject(PushNotificationsFacade);
  private readonly userStoreFacade = inject(UserStoreFacade);

  private readonly notificationsEnabled$ = this.authFacade.isAuthenticated$.pipe(
    switchMap((isAuthenticated) => {
      if (!isAuthenticated) {
        return of(false);
      }
      return this.userStoreFacade.readyNotification$.pipe(
        switchMap(() => {
          return this.userStoreFacade.user$;
        }),
        map((user) => (this.configStore.features?.notificationPanel?.enabled || user?.metaData?.notificationPanelEnabled) ?? false),
      );
    }),
  );

  private readonly userTaskBetaEnabled$$ = toSignal(
    this.configService.config$.pipe(map((config) => config.config.features.userTaskBeta.enabled)),
    { initialValue: false },
  );

  constructor() {
    this.updateDataWhenNotificationReceived();
  }

  /**
   * Client portal specific push notification action handler
   */
  async handlePushNotificationAction(notification: PushNotification) {
    const enabled = await firstValueFrom(this.notificationsEnabled$);
    if (!enabled) return;

    if (notification.data?.notification_id) {
      this.notificationsFacade.markAsRead([notification.data.notification_id]);
    }

    const path = notification.data?.path;

    if (Utils.isNotNil(path)) {
      const [mainPath, splitQueryParams] = path.split('?');
      const queryParams: { [key: string]: string } = {};
      if (path.includes('?')) {
        const params = new URLSearchParams(splitQueryParams);
        if (params.size > 0) {
          params.forEach((value, key) => {
            queryParams[key] = value;
          });
        }
      }

      // Backend needs to update the push notifications to change the id query parameter
      // to a path parameter. Then this code can be removed
      // Backend ticket logged https://app.clickup.com/t/2559043/SD-4625
      const idQueryParam = queryParams['id'];
      if (mainPath === `/${MainRouteNames.Magazine}` && Utils.isNotNil(idQueryParam)) {
        this.navigationService.navigateByUrl([mainPath, idQueryParam], {
          replaceUrl: true,
        });
        return;
      }
      if (mainPath === `/${MainRouteNames.Settings}/${SettingsRouteNames.Tasks}` && Utils.isNotNil(idQueryParam)) {
        if (this.userTaskBetaEnabled$$()) {
          this.navigationService.navigateByUrl([MainRouteNames.Settings, SettingsRouteNames.UserTasks, idQueryParam], {});
          return;
        }

        this.navigationService.navigateByUrl([mainPath, idQueryParam], {});
        return;
      }

      this.navigationService.navigateByUrl(mainPath, {
        queryParamsHandling: 'merge',
        queryParams,
        replaceUrl: true,
      });
    }
  }

  updateDataWhenNotificationReceived() {
    this.pushNotificationsFacade.notifications$
      .pipe(
        takeUntilDestroyed(),
        concatLatestFrom(() => this.notificationsEnabled$),
        filter(([, enabled]) => {
          return enabled;
        }),
      )
      .subscribe(([notification]) => {
        this.notificationsFacade.dispatchUpdateUnreadCount(Number(notification.data.notification_unread_count)); // to update unread notifications counter
        this.userStoreFacade.dispatchUpdateTaskCounts(Number(notification.data.task_unread_count)); // to update unread tasks counter
      });
  }
}
