import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PaginationMeta } from '@onyxx/model/pagination';
import { TaskDetail, UserTask } from '@onyxx/model/task';

export const userTaskApiActions = createActionGroup({
  source: 'User Task API',
  events: {
    'Reload tasks': props<{ showToastOnError: boolean }>(),
    'Reload tasks success': props<{ tasks: UserTask[]; paginationMeta: PaginationMeta }>(),
    'Reload tasks failure': props<{ errorMsg: string; showToastOnError: boolean }>(),

    'Load next page': emptyProps(),
    'Load page success': props<{ tasks: UserTask[]; paginationMeta: PaginationMeta }>(),
    'Load page failure': props<{ errorMsg: string; showToastOnError: boolean }>(),

    'Load task detail': props<{ id: TaskDetail['id']; showToastOnError: boolean }>(),
    'Load task detail success': props<{ taskDetail: TaskDetail }>(),
    'Load task detail failure': props<{ errorMsg: string; showToastOnError: boolean; id: TaskDetail['id'] }>(),
  },
});
