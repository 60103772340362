import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { userTaskFeature } from './user-task.reducer';
import { userTaskDetailFeature } from './user-task-detail.reducer';
import { userTaskApiActions } from './user-task-api.actions';
import { Actions, ofType } from '@ngrx/effects';
import { userTaskCommonActions } from './user-task-common.actions';
import { TaskDetail } from '@onyxx/model/task';
import { filter, from, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

const startAfterMicroTask = <T>(observable: Observable<T>): Observable<T> => {
  return from(new Promise<void>((r) => r())).pipe(switchMap(() => observable));
};

const subscribeWhen = <T>(dependency: Observable<boolean>, subscription: Observable<T>): Observable<T> => {
  // don't immediately subscribe to the dependency observable but give ngrx a change to update the state
  // - because the subscribe is sometimes preceded by a dispatch that updates the loading state
  return startAfterMicroTask(dependency).pipe(
    filter((v) => !v),
    take(1),
    switchMap(() => subscription),
  );
};

@Injectable()
export class UserTaskStoreFacade {
  readonly store = inject(Store);
  readonly actions = inject(Actions);

  readonly userTasks$ = subscribeWhen(
    this.store.select(userTaskFeature.selectBusyLoading),
    this.store.select(userTaskFeature.selectEntitiesWithMeta),
  );

  readonly taskCount$ = this.store.select(userTaskFeature.selectTaskCount);

  /** Critical and high priority tasks that need to be completed */
  readonly urgentTasks$ = subscribeWhen(
    this.store.select(userTaskFeature.selectBusyLoading),
    this.store.select(userTaskFeature.selectUrgentTasks),
  );

  readonly tasksLoadDone$ = this.actions.pipe(
    ofType(
      userTaskApiActions.loadPageSuccess,
      userTaskApiActions.loadPageFailure,
      userTaskApiActions.reloadTasksSuccess,
      userTaskApiActions.reloadTasksFailure,
    ),
  );

  taskDetail(id: string) {
    return subscribeWhen(
      this.store.select(userTaskDetailFeature.selectBusyLoading),
      this.store.select(userTaskDetailFeature.selectTaskById(id)),
    );
  }

  dispatchEnsureTaskDetailIsLoaded(taskId: TaskDetail['id'], options: { showToastOnError: boolean }) {
    this.store.dispatch(userTaskCommonActions.ensureTaskDetailIsLoaded({ id: taskId, showToastOnError: options.showToastOnError }));
  }

  dispatchLoadNextPage() {
    this.store.dispatch(userTaskApiActions.loadNextPage());
  }

  dispatchReloadTasks() {
    this.store.dispatch(userTaskApiActions.reloadTasks({ showToastOnError: true }));
  }

  dispatchMarkTaskAsStale(id: string) {
    this.store.dispatch(userTaskCommonActions.markTaskAsStale({ id }));
  }

  dispatchRemoveTaskFromStore(id: string) {
    this.store.dispatch(userTaskCommonActions.removeTask({ id }));
  }

  /** Skip a high priority task */
  dispatchSkipTask(id: string) {
    this.store.dispatch(userTaskCommonActions.skipTask({ id }));
  }
}
